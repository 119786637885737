<template>
    <div class="p-c-page mobile">
        <!-- 标题 -->
        <el-breadcrumb class="title">
            <el-breadcrumb-item>
                <router-link to="/">{{$t('layout.homePage')}}</router-link>
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{$t('layout.productsCenter')}}</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 产品菜单 -->
        <div class="product-menu">
            <categories></categories>
        </div>

        <!-- 产品列表 -->
        <div class="product-table">
            <router-link v-for="p in products" v-bind:key="p.itemId" class="product"
                         :to="`/product/product-detail?productId=${p.itemId}`">
                <!-- 保持240*320宽高比 -->
                <aspect-ratio class="image" ratio="125">
                    <img v-bind:src="$resources.autoTransform(p.coverImgUrl)" />
                </aspect-ratio>
                <div class="name">
                    {{$i18n.locale === 'zh-CN' ? p.zhName : p.enName}}
                </div>
            </router-link>
            <div v-if="products.length === 0 && $i18n.locale === 'zh-CN'" class="no-product">
                没有符合查询条件的产品，点击<router-link to="/product/product-center">此处</router-link>查看全部产品。
            </div>
            <div v-if="products.length === 0 && $i18n.locale === 'en-US'" class="no-product">
                No products found，click <router-link to="/product/product-center">hear</router-link> to view all products.
            </div>
        </div>

        <!-- 产品列表分页 -->
        <div class="product-pager">
            <el-pagination background layout="prev, pager, next" :total="productsPagination.total"
                           @current-change="currentPageChange"
                           @prev-click="currentPageChange(productsPagination.current-1)"
                           @next-click="currentPageChange(productsPagination.current+1)">
            </el-pagination>
        </div>

    </div>
</template>



<script>

    import ProductCategories from './product-categories';

    export default {

      components: {
        'categories': ProductCategories,
      },

      data() {
       return {

         products: [],
         productParam: {},
         productsPagination: {
           searchCount: true,
           size: 9,
           current: 1,
           total: 1,
         },
       };
      },

      created() {
        // 查询参数，二级分类ID
        const typeId = this.$route.query.typeId;
        if (typeId != null) {
          this.productParam.typeId = typeId;
        }

        // 查询参数，关键字
        const keyword = this.$route.query.keyword;
        if (keyword != null) {
          this.productParam.keyword = keyword;
        }

        const loading = this.$loading();
        Promise.all([
          this.loadProducts(),
        ]).then(() => loading.close());
      },

      watch: {
        $route(newValue, oldValue) {
          let updated = false;
          if (this.productParam.keyword !== newValue.query.keyword) {
            this.productParam.keyword = newValue.query.keyword;
            updated = true;
          }
          if (this.productParam.typeId !== newValue.query.typeId) {
            this.productParam.typeId = newValue.query.typeId;
            updated = true;
          }

          if (updated) {
            this.loadProducts();
          }
        }
      },

      methods: {

        loadProducts() {
          const param = JSON.parse(JSON.stringify(this.productParam));
          param.size = this.productsPagination.size;
          param.current = this.productsPagination.current;
          param.searchCount = this.productsPagination.searchCount;
          return this.$ajax.get('/item', {params: this.productParam}).then(response => {
            if (response.code !== 100) {
              return;
            }

            if (this.productsPagination.searchCount) {
              this.productsPagination.total = response.data.total;
              this.productsPagination.searchCount = false;
            }

            this.products = response.data.records;
          });
        },

        currentPageChange(page) {
          this.productsPagination.current = page;
          const loading = this.$loading();
          this.loadProducts().then(() => loading.close());
        },
      }
    }
</script>



<style lang="scss">
    .p-c-page.mobile {
        .el-breadcrumb {
            margin-left: 20px;
            padding: 20px 0;
        }

        .product-table {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .product {
                width: 33.3333%;
                padding: 5px 8px 15px 8px;
                position: relative;
                cursor: pointer;

                .image {
                    width: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        -webkit-transition: all .5s;
                        -moz-transition: all .5s;
                        -ms-transition: all .5s;
                        -o-transition: all .5s;
                        transition: all .5s;
                    }
                }

                .name {
                    height: 20px;
                    line-height: 20px;
                    font-size: 10px;
                    text-align: center;
                    color: var(--black);
                    background-color: rgb(242,242,242);

                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    position: absolute;
                    bottom: 2px;
                    left: 8px;
                    right: 8px;

                    padding-left: 5px;
                    padding-right: 5px;
                }

                .mask {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-size: 16px;
                    color: var(--white);
                    background-color: rgba(1,1,1, .4);

                    opacity: 0;
                    -webkit-transition: all linear .3s;
                    -moz-transition: all linear .3s;
                    -ms-transition: all linear .3s;
                    -o-transition: all linear .3s;
                    transition: all linear .3s;

                    position: absolute;
                    top: 15px;
                    right: 20px;
                    bottom: 15px;
                    left: 20px;
                    z-index: 10;
                }
                &:hover .mask {
                    opacity: 1;
                }
            }

            .no-product {
                width: 100%;
                line-height: 100px;
                font-size: 20px;
                text-align: center;

                a {
                    color: #409EFF;
                }
                a:hover {
                    color: red;
                }
            }
        }

        .product-pager {
            .el-pagination {
                padding-bottom: 20px;
                text-align: center;
            }
        }

    }
</style>